import { useRef, useState } from "react"
import { GoZenIcon } from "./icon/gozenicon"

export const PlanFeatureDetail = ({ plan, discribeplan = "", plantitleStatus = true }) => {

    const planIcon = {
        "starter": GoZenIcon({ width: "16", height: "16", pc: "#2563EB" }).starter_plan_icon,
        "premium": GoZenIcon({ width: "16", height: "16", pc: "#2563EB" }).preminum_plan_icon,
        "professional": GoZenIcon({ width: "16", height: "16", pc: "#2563EB" }).professional_plan_icon
    }

    const [ show , setShow ] = useState(false);
    
    const Ref = useRef(null);

    return (

        <p ref={Ref} className=" relative flex space-x-1.5  text-14px font-semibold  text-primary bg-[#2563EB10] w-fit px-3 py-1.5 rounded-lg capitalize cursor-pointer" onMouseOver={()=>setShow(true) } onMouseOut={()=>setShow(false) } >
            
            <span className="my-auto" >{planIcon[plan]}</span>


            { plantitleStatus && <span>
                {plan}
            </span>}


            { ( discribeplan !== "" ) && <p className={` absolute ${ show ? "opacity-100" : "opacity-0" } bg-white px-3 py-2 top-9 w-full min-w-[210px] font-normal rounded-lg border-1 flex z-50 text-black shadow-lg`} style={{ right:"0px", zIndex:100 }} > {discribeplan} </p>}
        </p>

    )

}