export const MenuAction = ( menuREf, setShow  ) => {

    const handleAction = (event) => {

        if (menuREf.current && !menuREf.current?.contains(event.target)) {

            setShow(false);
            
        }
    };

    document.addEventListener("mousedown", handleAction);

}

export const Menu_postion_setup = (event, setpopClass ) => {

    const winHeight = window.innerHeight;

    const winWidth = window.innerWidth;

    const blockHeight = event.target.offsetHeight;

    const blockWidth = event.target.offsetWidth;

    const mouse_p_Height = event.clientY;

    const per = (mouse_p_Height / winHeight) * 100;

    const balance = winHeight - mouse_p_Height;

    const { y, x } = event.target.getBoundingClientRect();

    console.log({blockHeight, y});

    console.log({ zIndex: '99999', width: `${event.target.offsetWidth}px`, top: `${(0+2) + y}px`,  right: `${winWidth - x -blockWidth}px` });

    
    if ((balance < ( blockHeight + 260 )) || (per > 78)) setpopClass({ zIndex: '99999', width: `${event.target.offsetWidth}px`, bottom: `${(winHeight+2) - y}px`,  }) //right: `${winWidth - x -blockWidth}px`

    else setpopClass({ zIndex: '99999', width: `${event.target.offsetWidth}px`, top: `${(blockHeight+2) + y}px`,   }) //right: `${winWidth - x -blockWidth}px`

}