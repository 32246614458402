

export const AppDrawerList = [
    {
        icon: (<svg width="35" height="35" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M379.418 304.348V400H283.766C258.397 400 234.068 389.922 216.13 371.984C198.191 354.046 188.114 329.716 188.114 304.348C188.114 278.979 198.191 254.65 216.13 236.712C234.068 218.773 258.397 208.696 283.766 208.696C309.134 208.696 333.464 218.773 351.402 236.712C369.34 254.65 379.418 278.979 379.418 304.348Z" fill="#0C233F"/>
        <path d="M96.8117 246.377C117.183 246.377 136.721 254.47 151.126 268.875C165.531 283.279 173.623 302.817 173.623 323.188C173.623 343.56 165.531 363.097 151.126 377.502C136.721 391.907 117.183 400 96.8117 400H20.0002V323.188C20.0002 302.817 28.0928 283.279 42.4977 268.875C56.9027 254.47 76.44 246.377 96.8117 246.377Z" fill="#17417F"/>
        <path d="M324.346 191.304C309.739 191.304 295.732 185.502 285.404 175.174C275.075 164.846 269.273 150.838 269.273 136.232C269.273 121.626 275.075 107.618 285.404 97.2897C295.732 86.9617 309.739 81.1594 324.346 81.1594H379.418V136.232C379.418 150.838 373.616 164.846 363.288 175.174C352.96 185.502 338.952 191.304 324.346 191.304Z" fill="#205BB2"/>
        <path d="M20.002 0H135.944C166.694 0 196.184 12.2153 217.927 33.9586C239.671 55.7019 251.886 85.1922 251.886 115.942C251.886 146.692 239.671 176.182 217.927 197.925C196.184 219.669 166.694 231.884 135.944 231.884C105.194 231.884 75.7039 219.669 53.9606 197.925C32.2173 176.182 20.002 146.692 20.002 115.942L20.002 0Z" fill="#2563EB"/>
        </svg>
        ),
        id: "gozen",
        name: "Dashboard",
        redirectUrl: { 
            dev: "https://dev.app.gozen.io/dashboard",
            prod: "https://app.gozen.io/dashboard"
        }
    },

    {
        icon: (<svg width="35" height="35" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M138.17 106.3C137.636 106.673 136.98 106.828 136.335 106.734C135.691 106.64 135.107 106.304 134.701 105.794C134.296 105.284 134.1 104.639 134.154 103.99C134.208 103.341 134.507 102.736 134.99 102.3L233.59 9.10003C233.764 8.93676 233.887 8.72806 233.948 8.49774C234.008 8.26741 234.003 8.02476 233.932 7.79745C233.861 7.57014 233.727 7.36732 233.547 7.21218C233.366 7.05704 233.146 6.95582 232.91 6.92004C223.81 5.63706 214.631 4.99547 205.44 5.00002H194.56C87.1102 5.00002 0.000197222 92.1 0.000197222 199.56C-0.0688545 247.994 17.9968 294.699 50.6402 330.48C50.7646 330.616 50.9169 330.723 51.0867 330.795C51.2565 330.867 51.4398 330.901 51.624 330.895C51.8082 330.89 51.989 330.844 52.154 330.762C52.319 330.68 52.4644 330.563 52.5802 330.42L142.26 218.29C142.419 218.081 142.509 217.827 142.518 217.564C142.527 217.301 142.454 217.042 142.309 216.822C142.164 216.603 141.954 216.434 141.709 216.338C141.464 216.243 141.195 216.226 140.94 216.29L49.9902 240.15C49.7104 240.227 49.4127 240.207 49.1459 240.093C48.8791 239.978 48.6591 239.777 48.5219 239.521C48.3847 239.265 48.3386 238.971 48.3909 238.685C48.4433 238.4 48.5911 238.14 48.8102 237.95L288.47 25.51C288.628 25.3707 288.748 25.1944 288.821 24.9971C288.893 24.7997 288.916 24.5874 288.886 24.3792C288.856 24.171 288.775 23.9735 288.65 23.8043C288.525 23.6352 288.36 23.4997 288.17 23.41C283.323 21.13 278.37 19.0467 273.31 17.16C273.12 17.0913 272.916 17.0673 272.715 17.0898C272.514 17.1124 272.321 17.1809 272.15 17.29L138.17 106.3Z" fill="#2563EB"/>
        <path d="M165.84 389.93C165.667 390.093 165.543 390.302 165.483 390.532C165.422 390.763 165.428 391.005 165.499 391.233C165.57 391.46 165.703 391.663 165.884 391.818C166.064 391.973 166.285 392.074 166.52 392.11C175.794 393.443 185.151 394.112 194.52 394.11H205.4C312.85 394.11 399.96 307 399.96 199.55C400.019 150.947 381.816 104.095 348.96 68.28C348.836 68.1449 348.684 68.0383 348.515 67.9675C348.345 67.8968 348.163 67.8636 347.979 67.8702C347.796 67.8769 347.616 67.9232 347.452 68.006C347.288 68.0889 347.144 68.2062 347.03 68.35L257.33 180.49C257.176 180.701 257.09 180.955 257.084 181.217C257.079 181.478 257.153 181.736 257.298 181.954C257.443 182.172 257.651 182.34 257.895 182.436C258.138 182.532 258.406 182.551 258.66 182.49L349.69 158.64C349.968 158.567 350.262 158.59 350.525 158.705C350.788 158.819 351.005 159.019 351.141 159.271C351.277 159.524 351.324 159.815 351.275 160.097C351.226 160.38 351.084 160.638 350.87 160.83L111.09 373.39C110.932 373.529 110.811 373.704 110.738 373.901C110.665 374.098 110.641 374.31 110.67 374.518C110.699 374.726 110.779 374.924 110.903 375.094C111.027 375.263 111.191 375.399 111.38 375.49C116.214 377.777 121.147 379.867 126.18 381.76C126.369 381.829 126.571 381.853 126.77 381.83C126.97 381.807 127.162 381.739 127.33 381.63L261.51 292.5C262.044 292.127 262.7 291.972 263.345 292.066C263.989 292.16 264.574 292.496 264.979 293.006C265.384 293.516 265.58 294.161 265.527 294.81C265.473 295.459 265.174 296.064 264.69 296.5L165.84 389.93Z" fill="#2563EB"/>
        </svg>
        ),
        id: "gz_content_ai",
        name: "Content Ai",
        redirectUrl: { 
            dev: "https://app.dev.content-ai.gozen.io/",
            prod: "http://app.content.gozen.io/"
        }
    },
    { 
        icon: (<svg width="35" height="35" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M375 0C205.583 0 66.2327 130.334 51.4663 295.966C79.6163 256.266 114.1 218.066 157.15 174.15C160.35 170.85 165.65 170.784 168.933 174.034C172.217 177.25 172.267 182.534 169.05 185.817C162.566 192.417 155.983 199.267 149.366 206.201C145.583 210.151 141.883 214.084 138.25 217.951C137.583 218.667 136.916 219.384 136.25 220.117C83.0499 277.084 45.0163 326.666 17.3991 388.25C15.5155 392.45 17.3991 397.384 21.5827 399.266C22.6991 399.766 23.8491 400 24.9991 400C28.1827 400 31.2327 398.166 32.6155 395.084C42.4491 373.167 53.8655 352.834 66.6991 333.05C183.099 330.066 289.049 265.316 344.583 162.284C345.966 159.7 345.899 156.584 344.399 154.05C342.883 151.55 340.165 150 337.233 150H306.366L361.716 118.366C363.466 117.366 364.816 115.75 365.466 113.85C377.333 79.35 383.333 43.85 383.333 8.33359C383.333 3.73359 379.6 0 375 0Z" fill="#2563EB"/>
        </svg>
        ),
        id: "gz_forms",
        name: "Forms",
        redirectUrl: { 
            dev: "https://dev.app.forms.gozen.io/workspace",
            prod: "https://app.forms.gozen.io/workspace"
        }
    },
    {
        icon: (<svg width="35" height="35" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.9871 53.3998C39.5962 38.2594 47.3447 18.6434 70.4698 13.2001C75.567 12.0056 80.8195 11.3605 86.087 11.2849L152.154 10.3638L151.96 107.306L73.472 93.1055C50.9432 89.0281 40.4232 70.2286 39.9871 53.3998Z" fill="#2563EB"/>
        <path d="M202.095 116.391L162.198 109.173L162.396 10.2316L322.162 8.00222C341.824 7.8533 360.278 15.2118 374.406 29.072C388.545 42.9373 396.474 61.4394 396.744 81.1713L398.947 241.887L347.865 248.782L298.092 250.6L285.975 198.585C272.298 136.431 245.303 126.88 202.095 116.391Z" fill="#2563EB"/>
        <path d="M46.2768 275.84L178.303 152.037C180.377 150.093 180.49 146.829 178.556 144.744C176.623 142.658 173.377 142.544 171.303 144.489L39.2772 268.291C37.2037 270.236 37.0903 273.5 39.0239 275.585C39.9907 276.628 41.2856 277.177 42.5979 277.223C43.9102 277.27 45.2401 276.812 46.2768 275.84Z" fill="#2563EB"/>
        <path d="M244.599 218.403L116.955 346.769C114.95 348.785 114.95 352.051 116.955 354.067C117.957 355.075 119.27 355.579 120.583 355.579C121.897 355.579 123.21 355.075 124.212 354.067L251.856 225.701C253.861 223.685 253.861 220.419 251.856 218.403C249.852 216.387 246.604 216.387 244.599 218.403Z" fill="#2563EB"/>
        <path d="M182.139 212.441C180.134 210.425 176.887 210.425 174.882 212.441L1.50358 386.793C-0.501195 388.809 -0.501195 392.075 1.50358 394.091C2.50597 395.099 3.81911 395.603 5.13224 395.603C6.44537 395.603 7.7585 395.099 8.76089 394.091L182.139 219.739C184.144 217.723 184.144 214.457 182.139 212.441Z" fill="#2563EB"/>
        <path d="M396.128 343.974C389.136 364.709 371.208 371.392 356.779 370.641C342.435 369.94 323.84 361.226 318.658 338.964L300.441 260.761C314.835 264.422 330.638 265.508 347.849 264.022C365.061 262.535 382.135 258.608 399.073 252.241L399.992 319.257C400.112 327.689 398.809 336.006 396.128 343.974Z" fill="#2563EB"/>
        </svg>
        ),
        id: "optinly",
        name: "Optinly",
        redirectUrl: { 
            dev: "http://app.dev.optinly.gozen.io/dashboard",
            prod: "http://app.optinly.com/dashboard"
        }
    },
    {
        icon: (<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 30 30" fill="none">
        <path d="M26.0156 18.9844C28.2161 18.9844 30 17.2005 30 15C30 12.7995 28.2161 11.0156 26.0156 11.0156C23.8151 11.0156 22.0312 12.7995 22.0312 15C22.0312 17.2005 23.8151 18.9844 26.0156 18.9844Z" fill="#2563EB"/>
        <path d="M17.8515 19.0171L21.2932 18.2929L23.0193 26.4959L19.5775 27.2201L17.8515 19.0171ZM17.8452 10.9896L19.5713 2.78662L23.0131 3.51084L21.287 11.7138L17.8452 10.9896Z" fill="#2563EB"/>
        <path d="M16.1293 19.3621L14.2521 19.7581C13.92 19.828 13.5816 19.8633 13.2422 19.8633C10.5606 19.8633 8.3789 17.6817 8.3789 15C8.3789 12.3183 10.5606 10.1367 13.2422 10.1367C13.5817 10.1367 13.9215 10.1721 14.2522 10.2419L16.1293 10.6379L17.8591 2.43955L15.9825 2.04363C15.0814 1.8537 14.1631 1.75791 13.2422 1.75781C5.94041 1.75781 0 7.69822 0 15C0 22.3018 5.94041 28.2422 13.2422 28.2422C14.162 28.2422 15.0838 28.146 15.9819 27.9565L17.8591 27.5605L16.1293 19.3621Z" fill="#2563EB"/>
        </svg>),
        id: "gz_engage",
        name: "Engage",
        redirectUrl: { 
            dev: "https://app.dev.engage.gozen.io/",
            prod: "https://app.engage.gozen.io/"
        }
    },
    {
        icon: (<svg width="35" height="35" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M320.311 279.707C305.806 303.826 279.38 319.961 249.186 319.961H174.978C144.784 319.961 118.359 303.826 103.854 279.708C104.243 279.366 104.64 279.027 105.047 278.693L159.486 234.006L181.128 248.355C199.89 260.793 224.275 260.793 243.037 248.355L264.68 234.006L319.119 278.693C319.525 279.027 319.923 279.365 320.311 279.707Z" fill="#06152D"/>
        <path d="M332.123 189.294V237.024C332.123 244.395 331.161 251.542 329.357 258.346L279.145 224.417L332.123 189.294Z" fill="#06152D"/>
        <path d="M95.3911 260.442L146.879 225.651L92.0391 189.294V237.024C92.0391 245.157 93.2095 253.016 95.3911 260.442Z" fill="#06152D"/>
        <path d="M332.123 166.31L232.45 232.391C220.104 240.576 204.058 240.576 191.712 232.391L92.0391 166.31V162.816C92.0391 117.01 129.172 79.8776 174.977 79.8776H249.185C294.99 79.8776 332.123 117.01 332.123 162.816V166.31Z" fill="#2563EB"/>
        <path d="M169.709 43.4246H270.165C324.516 43.4246 368.575 87.4844 368.575 141.835V258.003C368.575 312.354 324.516 356.413 270.165 356.413H153.997C105.71 356.413 65.546 321.636 57.185 275.763L75.1212 273.493C79.9211 272.886 82.2634 267.31 79.3375 263.457L37.8619 208.84C34.936 204.987 28.9362 205.746 27.0623 210.206L0.499946 273.434C-1.37394 277.895 2.28352 282.711 7.08337 282.103L25.9692 279.714C36.3004 341.084 89.6887 387.838 153.997 387.838H270.165C341.871 387.838 400 329.709 400 258.003V141.835C400 70.1291 341.871 12 270.165 12H169.709C161.032 12 153.997 19.0346 153.997 27.7123C153.997 36.39 161.032 43.4246 169.709 43.4246Z" fill="#2563EB"/>
        </svg>
        ),
        id: "gz_growth",
        name: "Growth",
        redirectUrl: { 
            dev: "https://dev.app.growth.gozen.io/",
            prod: "https://app.growth.gozen.io/"
        }
    },
    {
        icon: (<svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0001 0C11.0219 0 7.20659 1.58035 4.39354 4.3934C1.5805 7.20644 0.000145367 11.0218 0.000145367 15C-0.00953731 17.1558 0.464609 19.2862 1.38765 21.2344C1.83682 22.1762 1.92682 23.2496 1.64077 24.2531L0.000145367 30C0.000145367 30 3.5814 28.9781 5.7564 28.3875C6.75375 28.1027 7.82086 28.1927 8.7564 28.6406C10.7941 29.5727 13.0147 30.0365 15.2552 29.9978C17.4956 29.9591 19.6989 29.4189 21.7032 28.4169C23.7075 27.415 25.4618 25.9768 26.8373 24.2079C28.2128 22.439 29.1745 20.3844 29.6518 18.195C30.1291 16.0057 30.1098 13.7372 29.5954 11.5563C29.081 9.37537 28.0844 7.33742 26.6791 5.59216C25.2737 3.8469 23.4951 2.43868 21.4741 1.47096C19.4531 0.503241 17.2409 0.000611252 15.0001 0ZM21.9658 13.6687L19.7533 16.5469L19.8564 20.175C19.8624 20.3499 19.8253 20.5235 19.7485 20.6807C19.6717 20.838 19.5575 20.9739 19.4159 21.0767C19.2742 21.1794 19.1096 21.2458 18.9363 21.27C18.763 21.2943 18.5864 21.2756 18.422 21.2156L15.0001 19.9969L11.5783 21.2156C11.4146 21.2706 11.2403 21.2866 11.0694 21.2621C10.8985 21.2377 10.7356 21.1736 10.5939 21.075C10.447 20.9689 10.3295 20.8272 10.2524 20.6632C10.1753 20.4992 10.1412 20.3183 10.1533 20.1375L10.2564 16.5094L8.04389 13.6313C7.93773 13.4937 7.8667 13.3323 7.83696 13.1611C7.80722 12.99 7.81967 12.8141 7.87323 12.6488C7.92679 12.4835 8.01985 12.3338 8.14433 12.2125C8.26882 12.0913 8.42098 12.0023 8.58764 11.9531L12.0658 10.9312L14.1189 7.94063C14.223 7.80455 14.3571 7.69428 14.5108 7.6184C14.6644 7.54251 14.8335 7.50304 15.0048 7.50304C15.1762 7.50304 15.3452 7.54251 15.4989 7.6184C15.6525 7.69428 15.7866 7.80455 15.8908 7.94063L17.9439 10.9312L21.422 11.9531C21.5887 12.0023 21.7408 12.0913 21.8653 12.2125C21.9898 12.3338 22.0829 12.4835 22.1364 12.6488C22.19 12.8141 22.2024 12.99 22.1727 13.1611C22.143 13.3323 22.0719 13.4937 21.9658 13.6313V13.6687Z" fill="#2563EB"/>
            </svg>
            
        ),
        id: "gz_testimonials",
        name: "Testimonials",
        redirectUrl: { 
            dev: "https://dev.app.testimonials.gozen.io/",
            prod: "https://app.testimonials.gozen.io/"
        }
    },
    {
        icon: (<svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0724 2.46601C15.1809 2.28545 15.3761 2.17499 15.5867 2.17499H21.3858C21.875 2.17499 22.1586 2.72907 21.8724 3.12591L20.2454 5.38231H27.8329C28.2375 5.38231 28.6153 5.49672 28.9359 5.69494L18.8654 15.7618C18.6131 15.9957 18.3296 16.06 18.0643 16.0388C17.7777 16.0158 17.5456 15.8946 17.4512 15.8097C17.2357 15.6157 16.9037 15.6332 16.7098 15.8487C16.5158 16.0642 16.5333 16.3962 16.7488 16.5901C17.0294 16.8426 17.4847 17.0457 17.9805 17.0854C18.4958 17.1267 19.0877 16.9931 19.5895 16.5223L19.5955 16.5166L21.0303 15.0824L28.8559 22.9081C28.5532 23.0773 28.2043 23.1737 27.8329 23.1737H11.9118C11.5571 23.1737 11.2335 23.0906 10.952 22.9465L6.76521 27.585C6.29817 28.1024 5.47291 27.5416 5.78207 26.9169L11.5195 15.3233H10.7394L1.76856 14.8882L9.91839 14.5869L10.4594 14.5733H11.7611C12.2061 14.5733 12.4963 15.0407 12.2989 15.4395L6.93558 26.2769L11.7905 20.8982L21.0953 10.148H15.5042L19.3059 5.40294L21.0927 2.92499H15.6716L11.2874 10.2231C11.1789 10.4037 10.9837 10.5141 10.7731 10.5141H10.4188L0.0629883 10.0061L9.9597 9.76412H10.6882L15.0724 2.46601Z" fill="#2563EB"/>
            <path d="M21.7729 14.34L29.6161 22.1833C29.8169 21.8614 29.9329 21.4811 29.9329 21.0737V7.48231C29.9329 7.10805 29.835 6.75665 29.6634 6.45234L21.7729 14.34Z" fill="#2563EB"/>
            <path d="M12.6682 2.95487H13.5752C13.7616 2.95487 13.9127 2.80377 13.9127 2.61737C13.9127 2.43097 13.7616 2.27987 13.5752 2.27987H13.358L3.22559 2.6166L12.6682 2.95487Z" fill="#2563EB"/>
            <path d="M11.0932 6.25487H12.0002C12.1866 6.25487 12.3377 6.10377 12.3377 5.91737C12.3377 5.73097 12.1866 5.57987 12.0002 5.57987H11.783L1.65058 5.9166L11.0932 6.25487Z" fill="#2563EB"/>
            </svg>
            
        ),
        id: "hyper_reach",
        name: "Hyper\nReach",
        redirectUrl: { 
            dev: "https://dev.app.growth.gozen.io/",
            prod: "https://app.hyperreach.gozen.io/"
        }
    },
    {
        icon: (<svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2455 27.8497H10.7522C10.7807 27.9051 10.8175 27.9713 10.8637 28.0457C10.9986 28.2629 11.2141 28.5497 11.5391 28.8357C12.1925 29.4107 13.2749 29.97 14.9988 29.97C16.7228 29.97 17.8052 29.4107 18.4586 28.8357C18.7836 28.5497 18.999 28.2629 19.134 28.0457C19.1802 27.9713 19.2169 27.9051 19.2455 27.8497Z" fill="#06152D"/>
            <path d="M12.6904 2.30563C13.5073 2.10163 14.2829 1.99542 15.1733 2.00359C15.9109 2.01037 16.7123 2.09967 17.4061 2.26006C17.3984 2.19773 17.3859 2.11519 17.366 2.01863C17.3216 1.8031 17.2392 1.51309 17.0862 1.22089C16.7756 0.627801 16.1732 0.0299683 15.0509 0.0299683C13.9285 0.0299683 13.3261 0.627801 13.0155 1.22089C12.8625 1.51309 12.7801 1.8031 12.7357 2.01863C12.7134 2.12682 12.7004 2.21741 12.693 2.2817C12.692 2.29013 12.6912 2.29811 12.6904 2.30563Z" fill="#06152D"/>
            <path d="M5.3004 10.7805C6.37456 6.48551 10.2708 2.74773 15.1735 2.79275C20.0214 2.83727 24.0542 6.78328 25.0538 10.7804C25.1395 11.1232 25.1899 11.8896 25.2086 12.8991C25.2269 13.8902 25.2147 15.068 25.1901 16.2003C25.1655 17.3319 25.1286 18.4148 25.0978 19.215C25.0824 19.615 25.0686 19.9442 25.0586 20.1733C25.0536 20.2878 25.0496 20.3773 25.0468 20.4382C25.0454 20.4686 25.0443 20.4918 25.0436 20.5074L25.0426 20.5294L25.042 20.5403V20.5496C25.042 21.05 25.2317 21.4717 25.4792 21.8394C25.6826 22.1416 25.9435 22.4322 26.1903 22.7072C26.236 22.7582 26.2812 22.8086 26.3255 22.8584C26.9146 23.5211 27.4096 24.1625 27.4096 25.0875C27.4096 26.1718 26.7298 27.0605 25.8312 27.0605H4.32569C3.70979 27.0605 3.28515 26.8862 3.01046 26.5931C2.7319 26.2959 2.54999 25.8154 2.55 25.0875C2.55002 24.2513 3.03934 23.6137 3.63823 22.9181C3.67222 22.8787 3.70666 22.8389 3.74139 22.7988L3.74186 22.7982C3.99961 22.5004 4.27257 22.1851 4.48441 21.8575C4.72935 21.4787 4.91759 21.048 4.91759 20.5496H4.52299C4.91759 20.5496 4.91759 20.5496 4.91759 20.5496L4.9176 20.5261C4.91761 20.5105 4.91764 20.4872 4.91769 20.4568C4.91778 20.3959 4.91797 20.3064 4.91836 20.1918C4.91913 19.9626 4.92067 19.6333 4.92374 19.2332C4.9299 18.4327 4.94221 17.3495 4.96682 16.2174C4.99144 15.0847 5.02832 13.906 5.08345 12.9136C5.13944 11.9059 5.21256 11.1317 5.3004 10.7805Z" fill="#2563EB"/>
            </svg>
            
        ),
        id: "gz_notify",
        name: "Notify",
        redirectUrl: { 
            dev: "https://dev.app.growth.gozen.io/",
            prod: "https://app.notify.gozen.io/"
        }
    }
]