import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import PrimaryButton from "./Button1/primarybutton";
import { getTemplate } from "../actions/templates";
import { LoadingSpinner } from "./Loading";
import { listOfIcon } from "../content/iconList";
import { PlanFeaturePermission } from "../content/appFeaturePremisstion";
import toast from "react-hot-toast";
import { GoZenIcon } from "./icon/gozenicon";
import { PlanFeatureDetail } from "./planIcon";

export const SideTapFields = ({ fileds = [], cancel = null }) => {

    const [showSecondScreen, setShowSecondScreen] = useState(false)
    const [sidepanelTitle, setSidepanelTitle] = useState('')
    const [sidepanelTemps, setSidepanelTemps] = useState([])
    const [loading, setloading] = useState(false);

    const {  userPlan  } = useSelector((state) => ({ userPlan:state.userPlan }))


    const { gateway='stripe', type="monthly", plan="free" } = userPlan;

    const PFPInfo = PlanFeaturePermission[(gateway == 'local' ? "stripe": gateway)][plan];

    const CategoryPrice = { 
        "games":"free",
        'quizzes':"free", 
        "spinWheel":"free",
        'ecommerce':"starter",
        'poll':"premium", 
        'calculater':"professional",  
        'surveys':"professional"
    };

    const planIcon = {
        "starter":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).starter_plan_icon,
        "premium":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).preminum_plan_icon,
        "professional":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).professional_plan_icon
    }

    const Dispatch = useDispatch()

    const fieldsRedir = (fieldContent) => {
        if (fieldContent?.sidePanel) {
            setShowSecondScreen(true)
            setSidepanelTitle(fieldContent?.label)
            setSidepanelTemps(fieldContent?.templates)
        }
        else {
            Dispatch(Actions.setEditorFields(fieldContent))
            cancel()

        }

    }

    const menuREf = useRef(null);

    const CloseIcon = listOfIcon('close', 26, 26, '#586474');

    useEffect(() => {
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                // alert("test-out")
                cancel();
            }
        };

        document.addEventListener("mousedown", handleAction);
    }, [])

    const addFiledTemplate = async (template_id, category = "games") => {

        setloading(true)

        if( !PFPInfo[category] ) { setloading(false); return toast.error("To access this template, you need to upgraded your current plan.") }

        const data = await getTemplate(template_id)
        Dispatch(Actions.setAddTemplateData(data.data))
        cancel()
        setloading(false)
    }

    return (
        <div className="absolute flex w-full h-full bg-backgroundShadow left-0" style={{ zIndex: '100' }}>

            {loading && <LoadingSpinner className="absolute" />}

            <div className="flex w-fit h-full" ref={menuREf} >

                <div className="flex w-[350px] h-full flex-col border-r-2 bg-white" >

                    <div className="flex py-2 px-4 border-b-2 justify-between ">

                        <p className="my-auto font-medium text-lg">All Fields</p>

                        <PrimaryButton width="w-fit" backgroundColor="bg-white" border="border-none" OnClick={cancel} ring={'focus:outline-none'} icon={CloseIcon} />

                    </div>

                    {fileds?.length === 0 ? "0" :

                        <div className="w-full px-4 py-3 space-y-3 overflow-auto list-industry">

                            {
                                fileds.map((list, index) => {

                                    return (

                                        <div key={index} className="space-y-3">

                                            <p className="font-semibold capitalize">{list?.label}</p>

                                            {

                                                list?.fields?.map((Fileds, Index) => {
                                                    return (

                                                        <p key={Index} className="capitalize flex transition-all duration-500 px-4 py-3 font-medium border-1 cursor-pointer hover:shadow-lg rounded-lg hover:border-primary hover:text-primary justify-between" onClick={() => fieldsRedir(Fileds)}>
                                                            <span className="my-auto" >{Fileds?.label}</span>
                                                            {(Fileds?.templates?.[0]?.category && (CategoryPrice[Fileds?.templates?.[0]?.category] !== 'free')) && <PlanFeatureDetail plan={CategoryPrice[Fileds?.templates?.[0]?.category]} discribeplan={`Require ${CategoryPrice[Fileds?.templates?.[0]?.category]} plan.`} />}
                                                        </p>

                                                    )
                                                
                                                })
                                            
                                            }
                                        
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </div>

                {showSecondScreen && <div className="flex w-[800px] h-full flex-col bg-white" >

                    <div className="flex py-2 px-4 border-b-2 justify-between ">

                        <p className="my-auto font-medium text-lg capitalize">{sidepanelTitle} Templates</p>

                        <PrimaryButton width="w-fit" backgroundColor="bg-white" border="border-none" OnClick={() => setShowSecondScreen(false)} ring={'focus:outline-none'} icon={CloseIcon} />

                    </div>

                    <div className="w-full px-4 py-3 grid grid-cols-2 gap-4 overflow-auto list-industry">

                        {

                            sidepanelTemps?.map((temp, index) => {

                                return (

                                    <div key={index} className={` group rounded-3xl transition-all duration-400 ease-in-out mx-suto border-1 hover:shadow-xl shadow-gray-600 `}  >

                                        <div className="relative m-6">

                                            <img alt="Template" src={!temp?.image_src ? '/asset/images/templateCollect/Flying_Dice.png' : temp?.image_src} className="w-full min-h-[200px] rounded-2xl max-h-[200px] object-cover" />
                                            
                                            <div className="absolute transition-all duration-400 ease-in-out top-0 flex flex-col justify-center left-0 h-full w-full rounded-2xl bg-black/60 opacity-0 group-hover:opacity-100">
                                                
                                                <PrimaryButton id="View-Template" text={"use this Template"} buttontype={'defaultbutton'}  backgroundColor={"bg-white"} textcolor={"text-black"} padding={"py-6"} height={"h-14"} width={"w-1/2"} margin={"mx-auto mb-2"} OnClick={() => addFiledTemplate(temp?.template_id, temp?.category)} />
                                           
                                            </div>
                                        
                                        </div>
                                        
                                        <p className="mx-6 mb-6 font-medium text-base">{temp.name}</p>
                                   
                                    </div>
                                
                            )
                            })
                        }
                    
                    </div>
                
                </div>}
            
            </div>
       
        </div>
    
)
}