import React, { useState } from "react";
import Layout from ".";
import PrimaryButton from "../components/Button1/primarybutton";
import SearchBar from "../components/input/searchbar";
import ChooseInd from "../components/industry";
import { useEffect } from "react";
import { createcCampaign, templateList } from "../actions/templates";
import { TemplateCategoryBlock } from "../components/category";
import { PopupCreate } from "../components/popup/popupcreate";
import { LoadingSpinner } from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import { FloatTemplateHeader } from "../components/templateFloatBar";
import { AiPopupGC } from "../components/popup/GenerateAiContentPopup";
import { useNavigate } from "react-router-dom";
import { ProjectListAndCreate } from "../components/popup/projectlistandCreate";
import { createProject, getProjects } from "../actions/projects";
import toast from "react-hot-toast";
import { MediaPopup } from "../components/popup/Mediapopup";
import DefaultDropMenu from "../components/input/droplist";
import { Tutorial } from "../components/Tutorial";
import { useSelectionAction } from "./customstate/context";
import { PlanFeaturePermission } from "../content/appFeaturePremisstion";
import { GoZenIcon } from "../components/icon/gozenicon";
import { PlanFeatureDetail } from "../components/planIcon";


const Template = () => {

    const Dispatch = useDispatch()
    const {sidebarstate,setSidebarState} = useSelectionAction();
    const {  ShowPageMainContent } = useSelectionAction();


    setSidebarState(1)

    let url = window.location.search
    const quertData = new URLSearchParams(url)

    const categoryState = quertData.get('category') ?? 'games'
    const industryState = quertData.get('industry') ?? "all"

    const { projects } = useSelector(state => ({projects:state?.projects}))
    const { sharedProject } = useSelector(state => ({sharedProject:state?.sharedProject}))
    const { ChooseTemp } = useSelector(state =>({ChooseTemp:state.chooseTemplate}))
    const { completedTutorial, engage_apptour, userPlan  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour:state.engage_apptour, userPlan:state.userPlan }));

    const { gateway='stripe', type="monthly", plan="free" } = userPlan;

    const PFPInfo = PlanFeaturePermission[(gateway == 'local' ? "stripe": gateway)][plan];
console.log(PFPInfo,plan,gateway)

    const templateObj = [{
        title: "",
        srcurl: "",
        template_id: "",
        category: "",
        tempalate_plan: "",
    }]

    const categorylist = ['quizzes', 'ecommerce', 'calculater', 'poll', 'surveys']
    const categorylist2 = ["games",'quizzes', "spinWheel",'ecommerce','poll', 'calculater',  'surveys'] ;

    const CategoryPrice = { 
        "games":"free",
        'quizzes':"free", 
        "spinWheel":"free",
        'ecommerce':"starter",
        'poll':"premium", 
        'calculater':"professional",  
        'surveys':"professional"
    }

    const planIcon = {
        "starter":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).starter_plan_icon,
        "premium":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).preminum_plan_icon,
        "professional":GoZenIcon({ width:"16", height:"16", pc:"#2563EB" }).professional_plan_icon
    }
    
    const Navigate = useNavigate()

    const [templates, setTemplates] = useState({ games: templateObj });
    const [category, setCategory] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [softload, setSoftload] = useState(false);
    const [load, setload] = useState(false);
    const [ShowGenerate, setShowGenerate] = useState(false);
    const [ShowprojectPop, setShowprojectPop] = useState(false);
    const [ShowGeneratePop, setShowGeneratePop] = useState(false);
    const [ShowloadingSpin, setShowloadingSpin] = useState(false);
    const [seletedProject, setSeletedProject] = useState(0)
    const [trigger, settrigger] = useState('create')
    const [isMyWS,setisMyWS] = useState(true)
    const [ onChangePN , setOnchangePN ] = useState('My Project')
    const [ campaignName, setcampaignName ] = useState('My Campaign')
    const [ showCampPop, setCampPop ] = useState(false)
    const [ onChangeSrc , setOnchangeSrc ] = useState('')
    const [isActive, setActive] = useState(0);
    const [industryActive, setindustryActive] = useState(0);
    const [activeTemp, setactiveTemp] = useState(categoryState);
    const [activeIndustry, setActiveIndustry] = useState(industryState);
    const [showFloatHeader, setShowFloatHeader] = useState(false);
    const [templateName,settemplateName] = useState('')
    const [seletedTempID, setseletedTempID] = useState("")
    const [showMedia, setShoeMedia] = useState(false)
    const [lastTemp, setlastTemp] = useState(null)
    const [isLastTemp,setisLastTemp] =  useState(false)
    const [startTutorial, setStartTutorial] = useState(false)
    const [aiBtnTutorial, setaiBtnTutorial] = useState(false)


    const industryName = industry.map(list => list.title)

    useEffect(()=>{       
        setTimeout(()=>{ setStartTutorial((!completedTutorial?.templateGalary) && engage_apptour && ShowPageMainContent) },100)
       return(()=>{
        setStartTutorial(false)
    })
   },[engage_apptour])

   useEffect(()=>{      
    setTimeout(()=>{setaiBtnTutorial((!completedTutorial?.generateAiBtn) && ShowGenerate && engage_apptour  ) },100)  
    return(()=>{
        setaiBtnTutorial(false)
    })
   },[ShowGenerate,engage_apptour])

    useEffect(() => {
        setActiveIndustry(industry[industryActive]?.lable ?? "all")
        // Navigate(`?category=${category[isActive]?.lable}&industry=${industry[industryActive]?.lable}`)

    }, [industryActive])

    useEffect(() => {
        let isExist = categorylist.findIndex(list => list === activeTemp)
        if (isExist < 0) setShowGenerate(false)
        else  setShowGenerate(true)
        if (activeIndustry == 'all') { setisLastTemp(false); fetchTemplate()}

    }, [activeTemp])

    useEffect(() => {

        if (activeIndustry == 'all') { setisLastTemp(false); fetchTemplate()}

    }, [activeTemp])

    const mediaOnSelect = (src,type) => {
        setOnchangeSrc(src)
    }

    useEffect(() => {
        fetchTemplate()
        if(projects?.length == 0 ) getProject()
    }, [activeIndustry])

    // useEffect(() => {
    //     document.getElementById('templateGalleryblock').addEventListener('scroll', () => {
    //         console.log(document.getElementById('templateGalleryblock').scrollTop,"scroll ->>>>>>>>>>")
    //         })
    //     // .addEventListener('scroll', () => {
    //     //     document.getElementById('templateGalleryblock').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
    //     // })
    // },[])

    const TEmplateScoll = () => {
        try {
            (document.getElementById('templateGalleryblock').scrollTop > 210) ? setShowFloatHeader(true) : setShowFloatHeader(false);

            const Elem = document.getElementById('templateGalleryblock')

            if ( activeIndustry == 'all') {

                if ( ((Elem.scrollTop/Elem.offsetHeight)*100) > 70 ) ScrollFetch(lastTemp)

            }  
        }catch(Err) {
            // console.log(Err)
        }

     

    }

    const ScrollFetch = async (lastTemp = null) => {

        if(isLastTemp) return ;

        setload(true)

        let temp = {...templates}

        const template = await templateList(activeIndustry,activeTemp,lastTemp);

        setisLastTemp(template.data.templates?.isLastTemp)

        setlastTemp(template.data.templates[activeTemp][template.data.templates[activeTemp].length -1].template_id)

        temp[activeTemp] = [ ...temp[activeTemp],...template.data.templates[activeTemp] ]

        setTemplates(temp)

        setload(false)
    }

    const fetchTemplate = async (lastTemp = null) => {

        setSoftload(true)

        const template = await templateList( activeIndustry, activeTemp, lastTemp );

        setTemplates(template.data.templates)
        setCategory(template.data.category)
        setIndustry(template.data.industry)
        setisLastTemp(template.data.templates?.isLastTemp ?? false)
        if( activeIndustry == 'all') setlastTemp(template.data.templates[activeTemp][template.data.templates[activeTemp].length -1].template_id)

        else  setlastTemp(null)

        setSoftload(false)


let inx=categorylist2.findIndex(list => list === activeTemp)
        setActive(inx);
        // setactiveTemp(category[inx]?.lable);

    }

    const getProject = async () => {
        const ProjectList = await getProjects()
        if (ProjectList?.status) {
            Dispatch(Actions.setProjects(ProjectList?.data ?? []))
        }
        else {
            toast.error('Can\'t retrive the your Project')
        }
    }

    const changeactive = (index) => {
        setActive(index);
        setactiveTemp(category[index]?.lable);
        Navigate(`?category=${category[index]?.lable}&industry=${industry[industryActive]?.lable}`)
    }

    const CreateCampaign = async (templateId) => {

        const ActiveProject = isMyWS ? projects : sharedProject 

        if(ActiveProject.length < seletedProject ) return toast.error('Project not selected!')

        setShowloadingSpin(true)
        setCampPop(false)
        setSoftload(true)


        const payload = {
            "campaign": {
                "title": campaignName,
                "type": "inline_popup",
                "category": activeTemp,
                "project_id":ActiveProject[seletedProject]?.project_id,
                "intent": "",
                "industry": activeIndustry,
                "templateId": templateId
            }
        }
        var data;
        try{
             data = await createcCampaign( payload,!isMyWS );
        } catch(e){
            // console.log(e)
        }

        if (data?.success) isMyWS ? Navigate ( `/campaign/edit/${data.data.campaignId}`) : Navigate ( `/shared-workspace/campaign/edit/${ActiveProject[seletedProject]?.project_id}/${data.data.campaignId}`)

        else {

            setSoftload(false)
        }

        setShowloadingSpin(false)
    }

    const createProjectFun = async () => {

        setShowloadingSpin(true)

        const createProjectDetail =  await createProject( onChangePN ,onChangeSrc )
        if(createProjectDetail?.status){
            
            let data= [...projects, {project_id:createProjectDetail?.data?.project_id,project_name:onChangePN,project_img_src:onChangeSrc} ]
            setSeletedProject(data.length - 1)
            Dispatch(Actions.setProjects(data))
            toast.success(`project  created`)
            setCampPop(true)
            setShowprojectPop(false)
        }
        else toast('something wrong...!')

        setShowloadingSpin(false)


    }

    const viewTempate = async (template_id) => {

        Navigate ( `/template-preview/${template_id}` );

    }
    const CurrentActiveTemp = templates[activeTemp]?.length > 0 ? templates[activeTemp].filter((Obj) => String(Obj.name).toLowerCase().includes(String(templateName).toLowerCase())) : []
    // console.log(CurrentActiveTemp)
    const [indusrtys, setIndustrys] = useState(ChooseTemp);

    const listindustry = () => {

        setIndustrys(true);
    }

    return (
        <>
         {startTutorial && <Tutorial page="templateGalary"/>}
         {aiBtnTutorial && <Tutorial page="generateAiBtn"/>}

            { ShowGeneratePop && <AiPopupGC Cancel={setShowGeneratePop} category={activeTemp} /> }

            <Layout isactive={1} padding={""}>

                { showMedia && <MediaPopup OnSelect={mediaOnSelect} cancel={ (val) => {setShoeMedia(false); (trigger == 'create') ? setShowprojectPop(true): setShoeMedia(false)}} fieldType="src"  /> }

                { ShowprojectPop && <ProjectListAndCreate  onProfile={setShoeMedia} setisMyWS={setisMyWS} isMyWS={isMyWS} loadingLive={ShowloadingSpin} sharedProject={sharedProject} forwardAction={()=>{setCampPop(true);setShowprojectPop(false)}} projectData={projects} createProjectFun={createProjectFun} seletedProject={seletedProject} backwardAction={setShowprojectPop} changeDefaultVaule={setOnchangePN} setSeletedProject={setSeletedProject} title="select your workspace folder" /> }
                { showCampPop && <PopupCreate title="Campaign name" loadingLive={ShowloadingSpin} backwardAction={setCampPop} changeDefaultVaule={(e,t)=>setcampaignName(e.target.value)} defaultVaule={campaignName} forwardAction={() => CreateCampaign(seletedTempID)} /> }
                
                {
                    softload ? <LoadingSpinner opacity={category.length === 0 ? 'opacity-100' : 'opacity-50'} /> :

                        <div className={`relative   px-6 pt-4 h-full flex flex-col overflow-auto list-industry `} onScroll={TEmplateScoll} id="templateGalleryblock" >
                            <FloatTemplateHeader active={isActive} ShowHeader={showFloatHeader} category={category} changeactive={changeactive} />

                            <div className="relative flex flex-col h-full ">

                                {/* category */}
                                <div className="h-auto mt-4">
                                    <div className="flex justify-between ">
                                        <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Categories</h2>
                                        <p className=" step-chooseIndustry flex space-x-2 ">

                                            <DefaultDropMenu list={industryName} active={industryActive} SelectIndex={true} onchange={ind => { setindustryActive(ind); Navigate(`?category=${category[isActive]?.lable}&industry=${industry[ind]?.lable}`) } } label="Industry" spaceY="" mainclass="flex space-x-2" labelTextsize="my-auto" />
                                            {/* <span className="my-auto">Industry</span>
                                            <span className="border-1 my-auto py-2 w-[200px] px-2 rounded-md ">{industry[industryActive]?.title}</span> */}
                                            <PrimaryButton classNames={`${!indusrtys ? "block" : "hidden"}`} text="Choose Industry" margin="mr-8" padding_y="px-12" OnClick={() => setIndustrys(true)} />
                                            <PrimaryButton classNames={`${indusrtys ? "block" : "hidden"}`} text="Back to templates" margin="mr-8" backgroundColor="bg-border-light" textcolor="text-black" fontStyle="font-medium" padding_y="px-12" OnClick={() => setIndustrys(false)} />

                                        </p>
                                    </div>

                                    <div className="flex  my-6">
                                        {category.map((list, index) => {
                                            return <TemplateCategoryBlock key={index} src={list?.src} title={list.title} index={index} active={isActive} onclick={() => changeactive(index)} />

                                        })}
                                    </div>
                                </div>
                                {!indusrtys && <div className="w-full bg-white shadow-bgshadow space-y-6 h-fit rounded-3xl border-1 p-5">
                                    {/* Templates Block */}

                                    <div className="flex  justify-between">
                                        <div className="flex ">
                                            {/* space-x-5 */}
                                            <h2 className="font-bold text-22px text-primary-bold  capitalize mr-4 my-auto ">Templates</h2>
                                            {/* <PrimaryButton id="popular" text={"Popular"} rounded={"rounded-full"} />
                                            <PrimaryButton id="trendingNow" text={"Trending Now"} buttontype={'roundedbutton'} backgroundColor={"bg-white"} textcolor={"text-primary-gray"} />
                                            <PrimaryButton id="mostSold" text={"Most Sold"} buttontype={'roundedbutton'} backgroundColor={"bg-white"} textcolor={"text-primary-gray"} /> */}
                                        </div> 
                                        <div className="flex flex-row-reverse  space-x-3">
                                            {ShowGenerate && <button className="step-tempGal-generateAiBtn flex space-x-3 px-5 py-3 rounded-lg h-[45px] text-white justify-between" style={{background:`url('/asset/images/g2.png')0% 0% / 100% 100% no-repeat`}} onClick={() => {
                                                if(!PFPInfo[activeTemp]) return toast.error("Please Upgrad your plan."+PFPInfo[activeTemp]);
                                                else setShowGeneratePop(true);

                                                }}  >
                                                <span className="flex my-auto" ><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.91383 21.5352C5.91383 19.3599 4.14742 17.5935 1.97217 17.5935C4.14742 17.5935 5.91383 15.8271 5.91383 13.6519C5.91432 14.6971 6.32976 15.6994 7.06886 16.4385C7.80796 17.1776 8.81025 17.593 9.8555 17.5935C7.68025 17.5935 5.91383 19.3599 5.91383 21.5352Z" fill="white" fill-opacity="0.7" />
                                                    <path d="M15.7687 18.5784C15.7687 15.3151 13.1195 12.6659 9.8562 12.6659C13.1195 12.6659 15.7687 10.0168 15.7687 6.75342C15.7687 10.0168 18.4179 12.6659 21.6812 12.6659C18.4179 12.6659 15.7687 15.3151 15.7687 18.5784Z" fill="white" />
                                                    <path d="M7.88576 9.71048C7.88576 7.53523 6.11934 5.76881 3.94409 5.76881C6.11934 5.76881 7.88576 4.0024 7.88576 1.82715C7.88624 2.87239 8.30168 3.87469 9.04078 4.61379C9.77988 5.35289 10.7822 5.76833 11.8274 5.76881C9.65217 5.76881 7.88576 7.53523 7.88576 9.71048Z" fill="white" fill-opacity="0.4" />
                                                </svg>
                                                </span>
                                                <span className="flex my-auto font-semibold ">Create Using AI</span>
                                            </button>
                                            }

                                            <SearchBar  border="border-2" height={'h-[45px]'} setName={settemplateName} />
                                        </div>
                                    </div>
                                    {/* Templates list */}

                                    <div className={`template h-fit  grid ${CurrentActiveTemp.length > 0 ? "grid-cols-3":""}  gap-6  relative`}>
                                        {CurrentActiveTemp.length > 0 ? CurrentActiveTemp.map((temp, index) => {

                                            if (  (activeIndustry == 'all') || (temp.Industry == activeIndustry)) return (

                                                <div key={index} className={` group relative top-0 hover:-top-2 rounded-3xl h-fit transition-all duration-500 ease-in-out mx-suto border-1 hover:shadow-xl shadow-gray-600`}  >
                                                    <div className={`${index === 0 ? "step-template-first" : ""} relative m-6 `}>
                                                        <div className="w-full rounded-3xl h-[235px]">
                                                            <img src={temp.image_src} className="w-full h-full object-cover rounded-3xl" alt="" />
                                                        </div>
                                                        <div className="absolute transition-all duration-400 ease-in-out top-0 flex flex-col justify-center left-0 h-full w-full rounded-3xl bg-black/60 opacity-0 group-hover:opacity-100">

                                                            <PrimaryButton id="View-Template" text={"View Template"} buttontype={'defaultbutton'} backgroundColor={"bg-white"} textcolor={"text-black"} padding={"py-6"} height={"h-14"} width={"w-1/2"} margin={"mx-auto mb-2"} OnClick={() => viewTempate(temp.template_id)} />
                                                            {(PFPInfo?.[activeTemp] )&& <PrimaryButton id="Use-This-Template" text={"Use This Template"} buttontype={'defaultbutton'} backgroundColor={"bg-white"} textcolor={"text-black"} padding={"py-6"} height={"h-14"} width={"w-1/2"} margin={"mx-auto mb-2"} OnClick={() => { setShowprojectPop(true); setseletedTempID(temp.template_id) }} />}

                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col justify-between mx-6 mb-6 space-y-3">

                                                        <p className="font-medium text-base my-auto">{temp.name}</p>

                                                        <PlanFeatureDetail plan={CategoryPrice[activeTemp]} discribeplan={ (CategoryPrice[activeTemp] == "free") ? "" : `Require ${CategoryPrice[activeTemp]} plan.`} />
                                    

                                                    </div>

                                                   
                                                </div>

                                            )
                                        }) : <p className="w-full text-center font-semibold text-xl capitalize mx-auto justify-center">no Data</p>}

                                        {
                                            load && <LoadingSpinner text={''} opacity={category.length === 0 ? 'opacity-100' : 'opacity-50'} />
                                        }

                                    </div>
                                </div>}
                            </div>
                            {/* industry list */}

                            {indusrtys && <div className={`absolute h-fit bg-white left-0   ${indusrtys ? "top-12" : "top-0"} w-full mt-8 `}>
                                <div className={`w-full p-2 pt-4 shadow-card-rate rounded-xl transition-all delay-900 duration-900 bottom-0  ${indusrtys ? "top-[-1000px]" : "top-0"}`}>
                                    <div className="w-full grid grid-cols-4 ">
                                            <ChooseInd list={industry} setIndustrys={setIndustrys} active={industryActive}
                                            setActive={(indexIndustry) => { setindustryActive(indexIndustry); Navigate(`?category=${category[isActive]?.lable}&industry=${industry[indexIndustry]?.lable}`) }} />
                                    </div>
                                </div>
                            </div>}

                        </div>
                }

            </Layout>
        </>
    )

}

export default Template;